import type { UiProductCardOptionsFilterVariable } from '@ui/components/UiProductCard/UiProductCardOptions/UiProductCardOptions.types'

export interface valueOptionsVariants {
  parentId: string
  optionId: string
  attributeCode: string
}

export interface urlRewritesProduct {
  url: string
}
export interface productValueOptionsVariants {
  id: number
  name: string
  sku: string
  canonical_url: string
  url_rewrites: urlRewritesProduct[]
}

export interface variantsConfigurableProduct {
  product: valueOptionsVariants
  attributes: UiProductCardOptionsFilterVariable[]
}

// https://docs.google.com/document/d/1c797zC1ezmFvQIRA70WLKz3d2rUmHKUsfjDo9_zX6Wc
export enum ErpStatus {
  WITHDRAWN_FROM_SALE = 'STOP',
}

export enum ProductType {
  VIRTUAL = 'virtual',
  SIMPLE = 'simple',
  CONFIGURABLE = 'configurable',
  BUNDLE = 'bundle',
}
